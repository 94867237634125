import React from "react";
import {Link} from "react-router-dom";
import Swal from 'sweetalert2';
import axios from 'axios'

function Footer() {
    
    return (
        <footer id="colophon" className="site-footer">
            <div className="footer_inner_wrapper footer tm-bg tm-bgimage-yes">
                <div className="site-footer-bg-layer tm-bg-layer"></div>
                <div className="site-footer-w">
                    <div className="footer-rows">
                        <div className="footer-rows-inner">
                        <div id="second-footer" className="sidebar-container second-footer tm-bg tm-textcolor-white tm-bgimage-no" role="complementary">
                            <div className="second-footer-bg-layer tm-bg-layer"></div>
                            <div className="container tm-container-for-footer">
                                <div className="second-footer-inner">
                                    <div className="row multi-columns-row">
                                    <div className="widget-area col-xs-12 col-sm-6 col-md-5 col-lg-5">
                                        <aside id="enhancedtextwidget-2" className="widget-odd widget-3 widget widget_text enhanced-text-widget">
                                            <div className="textwidget widget-text">
                                                <div className="footer_logo">
                                                <h3 className="widget-title">B.Com COLLEGES IN BANGALORE</h3>
                                                </div>
                                                <p>B.Com Colleges in Bangalore was established in the year 2020 with the main objective of assisting management aspirants to choose the best and ideal B.Com college and finalise an appropriate specialisation that would ensure personal and professional satisfaction.</p>
                                            </div>
                                        </aside>
                                    </div>
                                    <div className="widget-area col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                        <aside id="nav_menu-2" className="widget-even widget-2 widget widget_nav_menu">
                                            <h3 className="widget-title">LINKS</h3>
                                            <div className="menu-footer-links-container">
                                                <ul id="menu-footer-links-1" className="menu">
                                                <li className="menu-item"><Link to="/">Home</Link></li>
                                                <li className="menu-item"><Link to="/about-us">About us</Link></li>
                                                <li className="menu-item"><Link to="/colleges">B.Com Colleges</Link></li>
                                                <li className="menu-item"><Link to="/syllabus">B.Com Syllabus</Link></li>
                                                <li className="menu-item"><Link to="/blogs">Blogs</Link></li>
                                                <li className="menu-item"><Link to="/contact-us">Contact us</Link></li>
                                                </ul>
                                            </div>
                                        </aside>
                                    </div>
                                    <div className="widget-area col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                        <aside id="themetechmount-recent-posts-3" className="widget-even widget-2 widget themetechmount_widget_recent_entries">
                                            <h3 className="widget-title">CONTACT US</h3>
                                            <p>No. 309, 3rd Floor, Brigade Gardens,
                                                Church Street, MG Road, Bangalore,
                                                Karnataka, India - 560001
                                            </p>
                                            <p><a href="tel:+918095797575">+918095797575</a></p>
                                            <p><a href="mailto:info@bcomcollegesinbangalore.com">info@bcomcollegesinbangalore.com</a></p>
                                            <h3 className="widget-title" style={{marginTop:"15px",marginBottom:"20px"}}>FOLLOW US</h3>
                                            <ul className="social-icons">
                                                <li className="tm-social-facebook"><Link className="social-link" target="_blank" to="#"><i className="fa fa-facebook"></i></Link></li>
                                                <li className="tm-social-twitter"><Link className="social-link" target="_blank" to="#"><i className="fa fa-twitter"></i></Link></li>
                                                <li className="tm-social-instagram"><Link className="social-link" target="_blank" to="https://instagram.com/studyinbangalorecolleges"><i className="fa fa-instagram"></i></Link></li>
                                                <li className="tm-social-youtube"><Link className="social-link" target="_blank" to="https://youtube.com/channel/UChVSKwSYdV_Q48YM8lTmwdw"><i className="fa fa-youtube-play"></i></Link></li>
                                            </ul>
                                        </aside>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div id="bottom-footer-text" className="bottom-footer-text tm-bottom-footer-text site-info  tm-bg tm-bgcolor-transparent tm-textcolor-white tm-bgimage-no">
                        <div className="bottom-footer-bg-layer tm-bg-layer"></div>
                        <div className="container tm-container-for-footer">
                        <div className="bottom-footer-inner">
                            <div className="row multi-columns-row">
                                <div className="col-xs-12 col-sm-12 tm-footer2-left ">
                                    <div className="text-center">
                                    Copyright © 2024<script>document.write(new Date().getFullYear())</script> - bcomcollegesinbangalore.com
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;