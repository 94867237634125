import React from "react";
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser'
import { HashLink } from 'react-router-hash-link';
import countryFile from './Country';
import Select from 'react-select'
import { ThreeDots } from 'react-loader-spinner'
import checkNumber from './CheckMobileNumber';

export default function ProgramDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [program, setProgram] = useState([])
    const [otherPrograms, setOtherPrograms] = useState([])
    const [collegerates, setCollegerates] = useState([])
    const [meta, setMeta] = useState('')
	const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
	const [isLoading, setIsLoading] = useState(false)
    const mounted = useRef();
	const [mobile, setMobileValue] = useState([]);

	function selectCountry (event) {
		setStateValue([])
		const state_arr = countryFile.s_a[event.key].split("|"); 
		const stateArray = [];
		stateArray.push({
		  value: '', 
		  label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
		  stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
		  });
		}
		setStates(stateArray)
		var Country = event.value;
		let check = checkNumber.checkNumber(Country,mobile);
		if(check === 2){
			setMobileValue('')
		}
	}
	  
	function selectRegion (val) {
		if(val.value !== '' && val.value !== null){
		  setStateValue(val)
		} else {
		  setStateValue([])
		}
	}

	function updateMobile(value){
		setMobileValue(value)
	}

    useEffect(()=>{
        fetchProduct(params.id);
		const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
    },[params.id])
    const fetchProduct = async () => {
		setIsLoading(true)
        await axios.get(`/program-page-data/${params.id}`).then(({data})=>{
		    setProgram(data.program)
            setOtherPrograms(data.otherPrograms)
			setCollegerates(data.collegerates)
			setIsLoading(false)
          // console.log(data);
        }).catch(({response:{data}})=>{
			setIsLoading(false)
			Swal.fire({
				text:'Error!.',
				icon:"error"
			})
        })
      }
     function blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      function commentDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ '-' +month+'-' +year;
      }

      function enquirySubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

  return (
	!isLoading ? 
	program!==null?
    	<div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                    <title>{program.course}</title>
                    <meta property="og:image" content={`/storage/programs/${program.image}`} />
					<meta property="og:image:secure_url" content={`/storage/programs/${program.image}`} />
					<meta property="og:image:width" content="750" />
					<meta property="og:image:height" content="336" />
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:description" content={program.course} />
					<meta name="twitter:title" content={program.course} />
					<meta name="twitter:site" content="@collegesinbangalore" />
					<meta name="twitter:image" content={`/storage/programs/${program.image}`} />
					<meta name="twitter:image:alt" content={program.course} />
					<meta name="twitter:creator" content="@collegesinbangalore" />
            </Helmet>
            <div id="content" className="site-content container">
                    <div id="content-inner" className="site-content-inner row multi-columns-row">
					  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
						 <main id="main" className="site-main">
								<div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h3 className="tm-custom-heading ">{program.course}</h3> </div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																	<div className="tm-cta3-content-wrapper bz-content">
																		<div className="college-img tm-featured-wrapper tm-lp_course-featured-wrapper">
                                                                            <img width="750" height="336" src={`/storage/programs/${program.image}`} className="attachment-full size-full wp-post-image" alt={program.course}/>
                                                                        </div>
																		<div className="blog-details">
																			<div dangerouslySetInnerHTML={{ __html: program.content }} />																		
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
								</div>

						 </main>
					  </div>
					  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">

						<aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor:'#3aa0d1'}}>Apply now</h3>
							<div className="widget-content p-10 side-form">
								<form onSubmit={enquirySubmit} className="course-form clearfix">
									<div className="row">
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="name" placeholder="Name *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="email" name="email" placeholder="Email *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="phone" value={mobile} onChange={e => updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" placeholder="Phone *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="course" placeholder="Course *" required/>
												<input type="hidden" name="source" value="2"/> 
                                            </div>
										</div>
										<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={countries} name="country"  placeholder="Select Country" required onChange={selectCountry} />
											</div>
										</div>
                    					<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={selectRegion} />
											</div>
										</div>
										<div className="col-xs-12 text-center">
											<input type="submit" value="Submit" className="btn submit"/> </div>
									</div>
								</form>
							</div>
						 </aside>
						 <aside id="categories-5" className="rating widget-even widget-10 widget widget_categories">
							<h3 className="widget-title" style={{backgroundColor:'#a42f33'}}>Reviews & Ratings of BCom Colleges in Bangalore</h3>
							<div className="rate-box">
							<div className="recent-box text-left">
								{collegerates && collegerates.length > 0 && collegerates.map((reviews, index) => {
									const oneStar = (reviews.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
									const twoStar = (reviews.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
									const threeStar = (reviews.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
									const fourStar = (reviews.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
									const fiveSar = (reviews.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
									return (
									<div className="recent" key={`r${reviews.id}`}>
									<h6><span>
										<div className="rating-stars">
										<ul id="">
											<li className="star rate-this" title="1 star" data-value="1" style={{margin: 0}}>
											<i className={oneStar}></i>
											</li>
											<li className="star rate-this" title="2 stars" data-value="2" style={{margin: 0}}>
											<i className={twoStar}></i>
											</li>
											<li className="star rate-this" title="3 stars" data-value="3" style={{margin: 0}}>
											<i className={threeStar}></i>
											</li>
											<li className="star rate-this " title="4 stars" data-value="4" style={{margin: 0}}>
											<i className={fourStar}></i>
											</li>
											<li className="star rate-this " title="5 stars" data-value="5" style={{margin: 0}}>
											<i className={fiveSar}></i>
											</li>
										</ul> 
										</div></span> <span>|</span> {reviews.name} <span>|</span> 
										<Link to={`/colleges/${reviews.url}`}>{reviews.college}</Link> <span>|</span> <span>{reviews.date}</span>
									</h6>
									<p>{reviews.review}</p>
									</div>
									);
								})}
							</div>
							</div>
						 </aside>
						
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<a className="hover" href="tel:+918095797575"><h3 className="widget-title"><i className="fa fa-phone" aria-hidden="true"></i> Helpline: +918095797575</h3></a>
						 </aside>
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<Link className="hover" to="/syllabus"><h3 className="widget-title" style={{backgroundColor:'#3aa0d1'}}>BCom Syllabus <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></Link>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor:'#a42f33'}}>BCom Programs in Bangalore</h3>
							<ul className="p-10 similar-crs tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413 scrollable-programs">
								{otherPrograms && otherPrograms.length > 0 && otherPrograms.map((otherProgram, index) => {
                        			return (
										<li key={`p${otherProgram.id}`}><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">
											<Link to={`/programs/${otherProgram.slug}`}>{otherProgram.course}</Link></span>
										</li>
									);
								})}
							</ul>
						 </aside>
						 
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<a className="hover" href="https://bbacollegesinbangalore.com/" target="_blank"><h3 className="widget-title">Click to know about BBA Colleges in Bangalore <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></a>
						 </aside>
						 
						 
					  </aside>
				   </div>
              </div>
        </div>
	:
	<h1> Can't connect to server ! </h1>
	:
	<div className="row">
		<div className="col-md-12 text-center">
			<ThreeDots
			visible={true}
			height="100"
			width="100"
			color="#a31c33"
			radius="9"
			ariaLabel="three-dots-loading"
			wrapperStyle={{textAlign:'center'}}
			wrapperClass="loader"
			/>
		</div>
  </div>
  )
};

// export default NotificationDetails;