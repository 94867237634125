import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import Select from 'react-select'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';

export default class Syllabus extends Component {
    
    constructor(props){
        super(props);
        this.state={
          blogs:[],
          notifications:[],
          reviews:[],
		  countries: [], 
		  states: [],
		  stateValue:'',
		  mobile:''
        }
      }

	  selectCountry = (val) => {
		this.setState({ stateValue: '' });
		const state_arr = countryFile.s_a[val.key].split("|"); 
		const stateArray = [];
		stateArray.push({
			value: '', 
			label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
			stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
			});
		}
		this.setState({ states: stateArray });
		var Country = val.value;
        let check = checkNumber.checkNumber(Country,this.state.mobile);
        if(check === 2){
          this.setState({mobile:''});
        }
	  }
	
	  selectRegion = (val) => {
		if(val.value !== '' && val.value !== null){
			this.setState({stateValue:val});
		} else {
			this.setState({stateValue:''});
		}
	  }

	  updateMobile = (value) => {
		this.setState({mobile:value});
	  }
     
      componentDidMount() {
		const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
		this.getUser();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        let usersData=await axios.get('/syllabus-page-data')
                        .then(res=>{
                        //   return res.data;
                          this.setState({
                            blogs:res.data.blogs,
                            notifications:res.data.notifications,
                            reviews:res.data.collegerates
                          },()=>{
                            // console.log(this.state)
                          })
                        })
                        .catch(err=>{
                          console.log(err);
                        })
      }

      handleSubmit(event) {
        // console.log(event.target.country.value);
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        console.log(formData);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

	  notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

	  blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

    render(){
        const {blogs}=this.state;
        const {notifications}=this.state;
        const {reviews}=this.state;
		const { countries, states, stateValue, mobile } = this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                <title>Syllabus | B.Com Colleges in Bangalore</title>
            </Helmet>
              <div id="content" className="site-content container">
                    <div id="content-inner" className="site-content-inner row multi-columns-row">
					  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
						 <main id="main" className="site-main">
						 <div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h3 className="tm-custom-heading ">B.Com Syllabus</h3> </div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																	<div className="tm-cta3-content-wrapper">
																		<p>Below is an overview of the topics which the student will study in a BCom programme across 6 semesters:</p>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
												<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
													<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
														<div className="vc_column-inner  ">
														
																			<div className="wpb_wrapper">
																				<h5 className="tm-custom-heading" style={{marginTop:"20px",color:"#3aa0d1"}}>Subjects of Study</h5>
																				<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																					<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0">
																						<div className="vc_column-inner  ">
																							<div className="wpb_wrapper">
																								<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Financial Accounting</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Business Organisation & Management</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Micro Economics</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Business Mathematics</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Communicative English</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Human Values and Professional Ethics</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Advanced Accounting</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Mercantile Law</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Macro Economics</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Business Statistics</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Business Communication</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Business Statistics</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Workshop IT and E-Commerce</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Corporate Accounting</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Company Law</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Money, Banking and International Trade</span></li>
																								</ul>
																							</div>
																						</div>
																					</div>
																					<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0">
																						<div className="vc_column-inner  ">
																							<div className="wpb_wrapper">
																								<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Operation Research</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Human Resource Management</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Indian Economic Problems</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Corporate Accounting</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Indirect Tax Laws</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Cost Accounting</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Business Finance</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Management of Financial Services</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Income Tax</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Marketing Management</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Tally</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Industrial Relations and Labour Laws</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Financial Management</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Business Environment</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Entrepreneurship Development</span></li>
																									
																								</ul>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
														</div>
													</div>
												</div>
												<hr/>
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default" id="scholarships">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h3 className="tm-custom-heading ">Scholarships For B.Com</h3> </div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																</div>
															</div>
														</div>
													</section>
												</div>
												<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
													<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
														<div className="vc_column-inner  ">
															
																			<div className="wpb_wrapper">
																				<h5 className="tm-custom-heading" style={{marginTop:"20px",color:"#3aa0d1"}}>Types of Scholarships</h5>
																				<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																					<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																						<div className="vc_column-inner  ">
																							<div className="wpb_wrapper">
																								<p>A B.Com scholarship helps to ease the financial burden of candidates who want to pursue a B.Com Course from a prestigious institute or a university. These B.Com scholarships are available for Indian students who wish to pursue their dream of studying in top B.Com colleges and universities in India and abroad.</p>
																								<p><b>Different types of B.Com scholarships and financial aid are offered to Indian students who wish to pursue B.Com in India and abroad:</b></p>
																								<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Merit-based Scholarships - </b>based on excellent academic record</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Merit-cum-Aptitude-based Scholarships - </b>based on excellent academic record and individual aptitude</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Need-based Scholarships and Fellowships – </b>usually for candidates from low-income families</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Gender-based Scholarships – </b>usually for female students</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>External Scholarships – </b>sponsored by renowned companies and organizations</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>International Scholarships – </b>provided by international universities and institutes</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>National Scholarships – </b>provided by national universities and B-Schools</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>State Scholarships – </b>provided by the state government</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Private Scholarships – </b>provided by private universities and B-Schools</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Fee Waivers - </b>usually a specific percentage, from 5% to 100%, of the total fees is waived off</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Teaching Assistantship – </b>through part-time teaching jobs in the university</span></li>
																									<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Summer Fellowships – </b>summer internship opportunities to obtain practical exposure and income</span></li>
																								</ul>
																								<hr/>
																								<div className="bz-collapse2">
																									<div className="panel-group" id="scholarshipsCollapse" role="tablist" aria-multiselectable="true">
																										<div className="panel panel-default">
																										  <div className="panel-heading" role="tab" id="headingOneScholar">
																											<h4 className="panel-title">
																											  <a role="button" data-toggle="collapse" data-parent="#scholarshipsCollapse" href="#collapseOpenOneScholar" aria-expanded="true" aria-controls="collapseOpenOneScholar">
																												HOW TO AVAIL SCHOLARSHIPS?
																											  </a>
																											</h4>
																										  </div>
																										  <div id="collapseOpenOneScholar" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOneScholar">
																											<div className="panel-body">
																											  <p>It is important to begin research about scholarship application dates and deadlines at the earliest. Steps to avail a scholarship are:</p>
																											  <ol className="numbers-list">
																												  <li>There are two main categories of scholarships – Government and Private Scholarships. It is very important to choose which type of scholarship you want to avail before proceeding with applications.</li>
																												  <li>Research and monitor regularly about the announcement of scholarship exams notifications and details, application deadlines, and eligibility.</li>
																												  <li>Find out which Scholarships Exam eligibility criteria is matching with your academic qualifications.</li>
																												  <li>Fill the Application Form without missing the last date.</li>
																												  <li>Submit the requested important documents like previous academic transcripts, age and address proof, certificates of participation in sports and extra-curricular activities, proof of parent’s income (if applicable), statement of purpose, etc.</li>
																												  <li>Prepare well for competitive scholarship examinations and answer each question very carefully.</li>
																												  <li>Attend mock tests if possible.</li>
																												  <li>Some universities and institutes also conduct an additional Personal Interview round after the examination so it is good to be prepared.</li>
																												  <li>If you are applying to a university or college abroad, check for visa requirements.</li>
																											  </ol>
																											</div>
																										  </div>
																										</div>
																										<div className="panel panel-default">
																										  <div className="panel-heading" role="tab" id="headingTwoScholar">
																											<h4 className="panel-title">
																											  <a className="collapsed" role="button" data-toggle="collapse" data-parent="#scholarshipsCollapse" href="#collapseOpenTwoScholar" aria-expanded="false" aria-controls="collapseTwoScholar">
																												HOW TO PREPARE FOR SCHOLARSHIP EXAMINATIONS?
																											  </a>
																											</h4>
																										  </div>
																										  <div id="collapseOpenTwoScholar" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwoScholar">
																											<div className="panel-body">
																											  <p>The process of securing a scholarship is very competitive because there are many applicants but limited scholarship offers. To get a scholarship the candidate should stand out from others. This can be achieved by:</p>
																											  <ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Early organization, strategy, planning, time management, and preparation</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Review the mode, duration, syllabus, marking scheme, and examination pattern of the entrance exams</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Stay up-to-date with the latest news and current affairs of national and international significance</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Brush up on General Knowledge topics</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Prepare for Logical Reasoning questions with continuous practice</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Build Vocabulary, Comprehension, and Grammar</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Practice basic Mathematics and memorize formulae</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Hard work and determination are essential during preparation</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Avoid distractions like TV, music, and video games</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">If additional assistance and guidance is required, join an education advisory centre like Galaxy Educational Services, one of Bangalore’s best academic advisory and counselling centres</span></li>
																												<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Prepare for the four main categories of questions usually asked in competitive examinations – Mathematical Skills, Intelligence & Critical/Logical Reasoning, Verbal Ability & Language Comprehension and General Awareness.</span></li>
																											</ul>
																											</div>
																										  </div>
																										</div>
						
																									  </div>
																									</div>
																							</div>
																						</div>
																					</div>
																					
																				</div>
																			</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

						 </main>
					  </div>
					  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title">Notifications</h3>
							<ul className="p-10 tm-recent-post-list">
								{notifications && notifications.length > 0 && notifications.map((notification, index) => {
                        			return (
										<li className="tm-recent-post-list-li" key={`n${notification.id}`}>
											<Link to={`/notifications/${notification.slug}`}>
												<img width="150" height="150" src={`/storage/notifications/${notification.image2}`} alt=""/>
											</Link>
											<Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
											<span className="post-date">{this.blogDate(new Date(notification.updated_at))}</span>
										</li>
								    );
							    })}
							</ul>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#3aa0d1'}}>Blogs</h3>
							<ul className="p-10 tm-recent-post-list">
								{blogs && blogs.length > 0 && blogs.map((blog, index) => {
									return (
									<li className="tm-recent-post-list-li" key={`bl${blog.id}`}>
										<Link to={`/blogs/${blog.slug}`}><img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/></Link>
										<Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
										<span className="post-date">{this.notificationDate(new Date(blog.updated_at))}</span>
									</li>
									)
								})}
							</ul>
						 </aside>
						 <aside id="categories-5" className="rating widget-even widget-10 widget widget_categories">
							<h3 className="widget-title" style={{backgroundColor: '#3aa0d1'}}>Reviews & Ratings of B.Com Colleges in Bangalore</h3>
							<div className="rate-box">
							{reviews && reviews.length > 0 && reviews.map((review, index) => {
								const oneStar = (review.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
								const twoStar = (review.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
								const threeStar = (review.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
								const fourStar = (review.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
								const fiveSar = (review.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
								return (
									<div className="recent-box text-left" key={`n${review.id}`}>
										<div className="recent">
										<h6><span><div className="rating-stars">
											<ul id="">
												<li className="star rate-this" title="1 star" data-value="1" style={{margin: 0}}>
												<i className={oneStar}></i>
												</li>
												<li className="star rate-this" title="2 stars" data-value="2" style={{margin: 0}}>
												<i className={twoStar}></i>
												</li>
												<li className="star rate-this" title="3 stars" data-value="3" style={{margin: 0}}>
												<i className={threeStar}></i>
												</li>
												<li className="star rate-this " title="4 stars" data-value="4" style={{margin: 0}}>
												<i className={fourStar}></i>
												</li>
												<li className="star rate-this " title="5 stars" data-value="5" style={{margin: 0}}>
												<i className={fiveSar}></i>
												</li>
											</ul> 
										</div></span> <span>|</span> {review.name} <span>|</span> 
											<Link to={`/colleges/${review.url}`}>{review.college}</Link> <span>|</span> <span>{review.date}</span></h6>
										<p>{review.review}</p>
										</div>
									</div>
								);
                    		})}
							</div>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title">Apply now</h3>
							<div className="widget-content p-10 side-form">
								<form onSubmit={this.handleSubmit} className="course-form clearfix">
									<div className="row">
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="name" placeholder="Name *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="email" name="email" placeholder="Email *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" name="phone" placeholder="Phone *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="course" placeholder="Course *" required/>
												<input type="hidden" name="source" value="2"/> </div>
										</div>
										<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
											</div>
										</div>
                    					<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
											</div>
										</div>
										<div className="col-xs-12 text-center">
											<input type="submit" value="Submit" className="btn submit"/> </div>
									</div>
								</form>
							</div>
						 </aside>
						 
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<a className="hover" href="tel:+918095797575"><h3 className="widget-title"><i className="fa fa-phone" aria-hidden="true"></i> Helpline: +918095797575</h3></a>
						 </aside>
					  </aside>
				   </div>
              </div>
        </div>
        );
    }
}

// export default Home;