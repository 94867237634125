import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { useState, useEffect, Component, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from "./Slider";
import { HashLink } from 'react-router-hash-link';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import countryFile from './pages/Country';
import checkNumber from './pages/CheckMobileNumber';
import Select from 'react-select'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

function Navbar() {
    const url = window.location.pathname.split('/').pop();
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [mobile, setMobile] = useState('');
    const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
    const [blogTitle, seBlogTitle] = useState('')
    const [ProgramTitle, seProgramTitle] = useState('')
    const [collegeTitle, seCollegeTitle] = useState('')
    const [notificationTitle, seNotificationTitle] = useState('')
    

     //assigning location variable
     const location = useLocation();
     //destructuring pathname from location
     const { pathname } = location;
     //Javascript split method to get the name of the path in array
     const splitLocation = pathname.split("/");  

    function selectCountry (event) {
        setStateValue([])
        const state_arr = countryFile.s_a[event.key].split("|"); 
        const stateArray = [];
        stateArray.push({
          value: '', 
          label: 'Select'
        });
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        setStates(stateArray)

        var Country = event.value;
        let check = checkNumber.checkNumber(Country,mobile);
        if(check === 2){
            setMobile('');
        } 
    } 
        
    function selectRegion (val) {
        if(val.value !== '' && val.value !== null){
          setStateValue(val)
        } else {
          setStateValue([])
        }
    }
    const updateMobile = (value) => {
        setMobile(value);
      };

    useEffect(()=>{
        const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
        getBlogtitle();
    },[url])

    const getBlogtitle = async () => {
        if(splitLocation[1] === "blogs" && splitLocation[2]){
            axios.get(`/blog-page-data/${splitLocation[2]}`).then(({data})=>{
                seBlogTitle(data.blog.title)
            }).catch(({response:{data}})=>{
                
            })
        } else if(splitLocation[1] === "colleges" && splitLocation[2]){
            axios.get(`/college-page-data/${splitLocation[2]}`).then(({data})=>{
                seCollegeTitle(data.college.college)
            }).catch(({response:{data}})=>{
                
            })
        } else if(splitLocation[1] === "notifications" && splitLocation[2]){
            axios.get(`/notification-page-data/${splitLocation[2]}`).then(({data})=>{
                seNotificationTitle(data.notification.title)
            }).catch(({response:{data}})=>{
                
            })
        } else if(splitLocation[1] === "programs" && splitLocation[2]){
            axios.get(`/program-page-data/${splitLocation[2]}`).then(({data})=>{
                seProgramTitle(data.program.course)
            }).catch(({response:{data}})=>{
                
            })
        }
    }

    const renderMessage = () => {
        return <p>Welcome back!</p>;
    };
    const handleClick = (myLink) => () => {
        window.location.href=myLink;
    }
   
    
    function closeMenu()
    {
        jQuery('.mega-menu-toggle').removeClass('mega-menu-open');
    }
    

    function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        jQuery("#overlay").fadeIn(300);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset();
            jQuery('#enquiry').modal('hide');
            if(data.status === 200){
                setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
            } else if(data.status === 201){
                setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
            } else {
                setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                }) 
            }
        })
      }
     
    return (
        <header id="masthead" className=" tm-header-style-classic-highlight tm-main-menu-total-6">
            <div className="tm-header-block tm-mmenu-active-color-skin tm-dmenu-active-color-custom tm-dmenu-sep-grey">
                <div className="themetechmount-topbar-wrapper tm-bgcolor-darkgrey tm-textcolor-white">
                  <div className="themetechmount-topbar-wrapper tm-bgcolor-darkgrey tm-textcolor-white">
                      <div className="themetechmount-topbar-inner">
                        <div className="container tm-container-for-topbar">
                            <div className="tm-wrap tm-topbar-content">
                              <div className="tm-wrap-cell tm-align-left">
                                  <ul className="top-contact">
                                    <li>To know about BBA colleges in Bangalore, <Link to="https://www.bbacollegesinbangalore.com/" target="_blank">Click here</Link></li>
                                  </ul>
                                  <div className="tm-vc_btn3-container tm-vc_btn3-inline"><a className="tm-vc_general tm-vc_btn3 tm-vc_btn3-size-md tm-vc_btn3-shape-square tm-vc_btn3-style-flat tm-vc_btn3-weight-no tm-vc_btn3-color-skincolor top-btn" href="#enquiry" data-toggle="modal">Get Direct Admission now</a></div>
                              </div>
                              <div className="tm-wrap-cell tm-align-right">
                                  <ul className="topbar-nav-menu">
                                    <li><i className="fa fa-phone tm-skincolor"></i> <span className="hidden-xs">Helpline: </span><a href="tel:+918095797575">+91 8095797575</a></li>
                                  </ul>
                                  <div className="tm-vc_btn3-container tm-vc_btn3-inline"><a className="tm-vc_general tm-vc_btn3 tm-vc_btn3-size-md tm-vc_btn3-shape-square tm-vc_btn3-style-flat tm-vc_btn3-weight-no tm-vc_btn3-color-skincolor top-btn" href="#enquiry" data-toggle="modal">Apply now</a></div>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
                
                <div id="tm-stickable-header-w" className="tm-stickable-header-w tm-bgcolor-white">
                  <div id="site-header" className="site-header tm-bgcolor-white tm-sticky-bgcolor-white tm-mmmenu-override-yes tm-above-content-yes  tm-stickable-header">
                      <div className="site-header-main tm-wrap container tm-container-for-header">
                        <div className="tm-wrap-cell">
                            <div className="headerlogo themetechmount-logotype-image tm-stickylogo-no">
                              <h1 className="site-title"><Link className="home-link" to="/" title="BCOM Colleges"><span className="tm-sc-logo tm-sc-logo-type-image"><img className="themetechmount-logo-img standardlogo" alt="BCOM Colleges" src="/assets/img/logo.png"/></span></Link></h1>
                            </div>
                        </div>
                        <div id="site-header-menu" className="site-header-menu tm-wrap-cell">
                            <nav id="site-navigation" className="main-navigation" aria-label="Primary Menu" data-sticky-height="70">
                              <div id="mega-menu-wrap-tm-main-menu" className="mega-menu-wrap">
                                  <div className="mega-menu-toggle">
                                    <div className="mega-toggle-blocks-left"></div>
                                    <div className="mega-toggle-blocks-center"></div>
                                    <div className="mega-toggle-blocks-right">
                                        <div className='mega-toggle-block mega-menu-toggle-block mega-toggle-block-1' id='mega-toggle-block-1' tabIndex='0'> <span className='mega-toggle-label' role='button' aria-expanded='false'><span className='mega-toggle-label-closed'>MENU</span><span className='mega-toggle-label-open'>MENU</span></span>
                                        </div>
                                    </div>
                                  </div>
                                  <ul id="mega-menu-tm-main-menu" className="mega-menu max-mega-menu mega-menu-horizontal mega-no-js" data-event="hover_intent" data-effect="fade_up" data-effect-speed="200" data-effect-mobile="disabled" data-effect-speed-mobile="200" data-mobile-force-width="false" data-second-click="go" data-document-click="collapse" data-vertical-behaviour="standard" data-breakpoint="1200" data-unbind="true">
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/" tabIndex="0">Home</Link></li>
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "about-us" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/about-us" tabIndex="0">About us</Link></li>
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "colleges" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/colleges" tabIndex="0">B.COM Colleges</Link></li>
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "blogs" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/blogs" tabIndex="0">Blogs</Link></li>
                                    <li onClick={closeMenu} className={`mega-menu-item mega-${splitLocation[1] === "contact-us" ? "current-" : ""}menu-item`}><Link className="mega-menu-link" to="/contact-us" tabIndex="0">Contact us</Link></li>
                                    <li onClick={closeMenu} className="mega-menu-item mega-menu-item notification-btn last"><HashLink smooth className="mega-menu-link" to="/#notifications" tabIndex="0"><span className="bell fa fa-bell"></span> Notifications</HashLink></li>
                                    {/* <li className="mega-menu-item mega-menu-item"><HashLink className="mega-menu-link" smooth  to="/#notifications">Meet the Team</HashLink></li> */}
                                  </ul>
                              </div>
                            </nav>
                        </div>
                      </div>
                  </div>
                </div>
                {(() => {
                if(splitLocation[1] === ""){
                    return(
                        <div className="themetechmount-slider-wrapper">
                            <div className="themetechmount-slider-wide">
                            <div className="home-slider home-slider-1">
                            <div >
                            <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]} spaceBetween={50} slidesPerView={1} navigation simulateTouch={false} onSwiper={(swiper) => console.log('')} onSlideChange={() => console.log('')}>
                                <SwiperSlide>
                                        <div className="item">
                                            <article className="entry-item">
                                                <div className="entry-thumb"> <img width="1366" height="602" src="/assets/img/slider-1.jpg" className="attachment-upside-slide-one-1366-602 size-upside-slide-one-1366-602 wp-post-image lozad" alt="B.COM Colleges" />
                                                    <div className="mask"></div>
                                                </div>
                                                <div className="entry-content">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-3 col-sm-3 col-xs-12 left-col col-md-offset-4 col-sm-offset-4">
                                                                <div className="slider-content">
                                                                    <h2>Top <span>B.COM Colleges</span> in Bangalore</h2>
                                                                    <Link to="/colleges"><button className="tm-vc_general tm-vc_btn3 tm-vc_btn3-size-xs tm-vc_btn3-shape-square tm-vc_btn3-style-outline tm-vc_btn3-weight-no tm-vc_btn3-color-black" > Find your college</button></Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-sm-4 col-xs-12 right-col hidden-xs">
                                                                <div className="slider-form">
                                                                    <div className="widget">
                                                                        <h4 className="widget-title text-center">Quick Enquiry</h4>
                                                                        <p className="text-center">Apply now to get admission for B.COM</p>
                                                                        <div className="widget-content">
                                                                            <form onSubmit={handleSubmit} autoComplete="off">
                                                                                <div className="row">
                                                                                    <div className="col-xs-12">
                                                                                        <div className="text-block">
                                                                                            <input type="text" name="name" placeholder="Name *" required/> </div>
                                                                                    </div>
                                                                                    <div className="col-xs-12">
                                                                                        <div className="text-block">
                                                                                            <input type="email" name="email" placeholder="Email *" required/> </div>
                                                                                    </div>
                                                                                    <div className="col-xs-12">
                                                                                    <div className="text-block">
                                                                                        <input type="text" value={mobile} onChange={e => updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" name="phone" placeholder="Phone *" required/> 
                                                                                    </div>
                                                                                    </div>
                                                                                    <div className="col-xs-12">
                                                                                        <div className="text-block">
                                                                                            <input type="text" name="course" placeholder="Course *" required/>
                                                                                            <input type="hidden" name="source" value="2"/> </div>
                                                                                    </div>
                                                                                    <div className="col-xs-12">
                                                                                        <div className="text-block">
                                                                                            <Select options={countries} menuPlacement="top" name="country"  placeholder="Select Country" required onChange={selectCountry} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-12">
                                                                                        <div className="text-block">
                                                                                            <Select options={states} menuPlacement="top" value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={selectRegion} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-12 text-center">
                                                                                        <input type="submit" value="Submit" className="btn submit"/> </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    
                                </SwiperSlide>
                            </Swiper>
                            </div>
                            </div>
                            </div>
                            
                        </div>
                    );
                } else if(splitLocation[1] === "about-us"){
                    return(
                        <div className="tm-titlebar-wrapper tm-bg tm-bgcolor-custom tm-titlebar-align-default tm-textcolor-white tm-bgimage-yes tm-breadcrumb-bgcolor-custom about-tm-titlebar-wrapper">
                            <div className="tm-titlebar-wrapper-bg-layer tm-bg-layer"></div>
                            <div className="tm-titlebar entry-header">
                                <div className="tm-titlebar-inner-wrapper">
                                    <div className="tm-titlebar-main">
                                        <div className="container">
                                            <div className="tm-titlebar-main-inner">
                                                <div className="entry-title-wrapper"><div className="container"><h1 className="entry-title"> About Us</h1></div></div>
                                                <div className="breadcrumb-wrapper"><div className="container"><div className="breadcrumb-wrapper-inner"><span><Link to="/" className="home">Home</Link></span> <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>
                                                    <span className="post post-page current-item">About Us</span></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }else if(splitLocation[1] === "colleges" && !splitLocation[2]){
                    return(
                        <div className="tm-titlebar-wrapper tm-bg tm-bgcolor-custom tm-titlebar-align-default tm-textcolor-white tm-bgimage-yes tm-breadcrumb-bgcolor-custom colleges-tm-titlebar-wrapper">
                            <div className="tm-titlebar-wrapper-bg-layer tm-bg-layer"></div>
                            <div className="tm-titlebar entry-header">
                                <div className="tm-titlebar-inner-wrapper">
                                    <div className="tm-titlebar-main">
                                        <div className="container">
                                            <div className="tm-titlebar-main-inner">
                                                <div className="entry-title-wrapper"><div className="container"><h1 className="entry-title"> B.COM Colleges</h1></div></div>
                                                <div className="breadcrumb-wrapper"><div className="container"><div className="breadcrumb-wrapper-inner"><span><Link to="/" className="home">Home</Link></span> <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>
                                                    <span className="post post-page current-item">Colleges</span></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else if(splitLocation[1] === "colleges" && splitLocation[2]){
                    return(
                        <div className="tm-titlebar-wrapper tm-bg tm-bgcolor-custom tm-titlebar-align-default tm-textcolor-white tm-bgimage-yes tm-breadcrumb-bgcolor-custom college-details-tm-titlebar-wrapper">
                            <div className="tm-titlebar-wrapper-bg-layer tm-bg-layer"></div>
                                <div className="tm-titlebar entry-header">
                                    <div className="tm-titlebar-inner-wrapper">
                                        <div className="tm-titlebar-main">
                                            <div className="container">
                                                <div className="tm-titlebar-main-inner">
                                                    <div className="entry-title-wrapper">
                                                        <div className="container">
                                                            <h1 className="entry-title">{collegeTitle}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="breadcrumb-wrapper">
                                                        <div className="container">
                                                            <div className="breadcrumb-wrapper-inner">
                                                                <span><Link to="/" className="home">Home</Link></span> 
                                                                <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                                <Link to="/colleges" className="home">Colleges</Link></span> 
                                                                <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>
                                                                <span className="post post-page current-item">{collegeTitle}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    );
                } else if(splitLocation[1] === "contact-us"){
                    return(
                        <div className="tm-titlebar-wrapper tm-bg tm-bgcolor-custom tm-titlebar-align-default tm-textcolor-white tm-bgimage-yes tm-breadcrumb-bgcolor-custom contact-us-tm-titlebar-wrapper">
                            <div className="tm-titlebar-wrapper-bg-layer tm-bg-layer"></div>
                            <div className="tm-titlebar entry-header">
                                <div className="tm-titlebar-inner-wrapper">
                                    <div className="tm-titlebar-main">
                                        <div className="container">
                                            <div className="tm-titlebar-main-inner">
                                                <div className="entry-title-wrapper">
                                                    <div className="container">
                                                        <h1 className="entry-title"> Contact Us</h1>
                                                    </div>
                                                </div>
                                                <div className="breadcrumb-wrapper">
                                                    <div className="container">
                                                        <div className="breadcrumb-wrapper-inner">
                                                            <span><Link to="/" className="home">Home</Link></span>
                                                            <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>
                                                            <span className="post post-page current-item">Contact Us</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                } else if(splitLocation[1] === "blogs" && !splitLocation[2]){
                    return(
                        <div className="tm-titlebar-wrapper tm-bg tm-bgcolor-custom tm-titlebar-align-default tm-textcolor-white tm-bgimage-yes tm-breadcrumb-bgcolor-custom colleges-tm-titlebar-wrapper">
                            <div className="tm-titlebar-wrapper-bg-layer tm-bg-layer"></div>
                            <div className="tm-titlebar entry-header">
                                <div className="tm-titlebar-inner-wrapper">
                                    <div className="tm-titlebar-main">
                                        <div className="container">
                                            <div className="tm-titlebar-main-inner">
                                                <div className="entry-title-wrapper"><div className="container"><h1 className="entry-title">Blogs</h1></div></div>
                                                <div className="breadcrumb-wrapper"><div className="container"><div className="breadcrumb-wrapper-inner"><span><Link to="/" className="home">Home</Link></span> <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>
                                                    <span className="post post-page current-item">Blogs</span></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else if(splitLocation[1] === "blogs" && splitLocation[2]){
                    return(
                        <div className="tm-titlebar-wrapper tm-bg tm-bgcolor-custom tm-titlebar-align-default tm-textcolor-white tm-bgimage-yes tm-breadcrumb-bgcolor-custom blog-details-titlebar-wrapper">
						<div className="tm-titlebar-wrapper-bg-layer tm-bg-layer"></div>
						<div className="tm-titlebar entry-header">
							<div className="tm-titlebar-inner-wrapper">
								<div className="tm-titlebar-main">
									<div className="container">
										<div className="tm-titlebar-main-inner">
											<div className="entry-title-wrapper">
                                                <div className="container">
                                                    <h1 className="entry-title"> Blogs</h1>
                                                </div>
                                            </div>
											<div className="breadcrumb-wrapper">
                                                <div className="container">
                                                    <div className="breadcrumb-wrapper-inner">
                                                        <span><Link to="/" className="home">Home</Link></span>  
                                                        <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                                        <Link to="/blogs" className="home">Blogs</Link></span> 
                                                        <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>
												        <span className="post post-page current-item">{blogTitle}</span>
                                                    </div>
                                                </div>
											</div>
										</div>
									</div>
								</div>
							</div>
						    </div>
					    </div>
                    )
                } else if(splitLocation[1] === "programs" && splitLocation[2]){
                    return(
                        <div className="tm-titlebar-wrapper tm-bg tm-bgcolor-custom tm-titlebar-align-default tm-textcolor-white tm-bgimage-yes tm-breadcrumb-bgcolor-custom blog-details-titlebar-wrapper">
						<div className="tm-titlebar-wrapper-bg-layer tm-bg-layer"></div>
						<div className="tm-titlebar entry-header">
							<div className="tm-titlebar-inner-wrapper">
								<div className="tm-titlebar-main">
									<div className="container">
										<div className="tm-titlebar-main-inner">
											<div className="entry-title-wrapper">
                                                <div className="container">
                                                    <h1 className="entry-title"> Programs</h1>
                                                </div>
                                            </div>
											<div className="breadcrumb-wrapper">
                                                <div className="container">
                                                    <div className="breadcrumb-wrapper-inner">
                                                        <span><Link to="/" className="home">Home</Link></span>  
                                                        <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> 
                                                        <Link to="#" className="home">Programs</Link></span> 
                                                        <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>
												        <span className="post post-page current-item">{ProgramTitle}</span>
                                                    </div>
                                                </div>
											</div>
										</div>
									</div>
								</div>
							</div>
						    </div>
					    </div>
                    )
                } else if(splitLocation[1] === "notifications" && splitLocation[2]){
                    return(
                        <div className="tm-titlebar-wrapper tm-bg tm-bgcolor-custom tm-titlebar-align-default tm-textcolor-white tm-bgimage-yes tm-breadcrumb-bgcolor-custom colleges-tm-titlebar-wrapper">
                            <div className="tm-titlebar-wrapper-bg-layer tm-bg-layer"></div>
                            <div className="tm-titlebar entry-header">
                                <div className="tm-titlebar-inner-wrapper">
                                    <div className="tm-titlebar-main">
                                        <div className="container">
                                            <div className="tm-titlebar-main-inner">
                                                <div className="entry-title-wrapper"><div className="container"><h1 className="entry-title"> Notifications</h1></div></div>
                                                <div className="breadcrumb-wrapper"><div className="container"><div className="breadcrumb-wrapper-inner"><span><Link to="/" className="home">Home</Link></span> <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>
                                                    <span className="post post-page current-item">{notificationTitle}</span></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else if(splitLocation[1] === "syllabus"){
                    return(
                        <div className="tm-titlebar-wrapper tm-bg tm-bgcolor-custom tm-titlebar-align-default tm-textcolor-white tm-bgimage-yes tm-breadcrumb-bgcolor-custom colleges-tm-titlebar-wrapper">
                            <div className="tm-titlebar-wrapper-bg-layer tm-bg-layer"></div>
                            <div className="tm-titlebar entry-header">
                                <div className="tm-titlebar-inner-wrapper">
                                    <div className="tm-titlebar-main">
                                        <div className="container">
                                            <div className="tm-titlebar-main-inner">
                                                <div className="entry-title-wrapper"><div className="container"><h1 className="entry-title"> B.Com Syllabus</h1></div></div>
                                                <div className="breadcrumb-wrapper"><div className="container"><div className="breadcrumb-wrapper-inner"><span><Link to="/" className="home">Home</Link></span> <span className="tm-bread-sep"> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>
                                                    <span className="post post-page current-item">B.Com Syllabus</span></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
                })()}
            </div>
                            
                <div className="modal fade enquiry-modal" id="enquiry" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">×</button>
                                <h6 className="modal-title">Submit your details</h6>
                            </div>
                            <div className="modal-body">
                                <div className="modal-form">
                                    <div className="widget">
                                    <div className="widget-content">
                                    <form onSubmit={handleSubmit} className="course-form clearfix">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="text-block">
                                                <input type="text" name="name" placeholder="Name *" required/>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="text-block">
                                                <input type="email" name="email" placeholder="Email *" required/>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="text-block">
                                            <input type="text" className="EnquiryMobile" name="phone" placeholder="Phone *" required/>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="text-block">
                                                <input type="text" name="course" placeholder="Course *" required/>
                                                <input type="hidden" name="source" value="2"/>
                                            </div>
                                        </div>
                                        <div data-toggle-group="location">
                                            <div className="col-xs-12 pad-5">
                                                <div className="text-block">
                                                    <select name="country" className="select_2 country CountrySelect" data-toggle="country" data-country="" required>
                                                    
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 pad-5">
                                                <div className="text-block">
                                                    <select name="state" className="select_2 state" data-toggle="state" data-state="" required>
                                                    <option>Select State</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="col-xs-12 text-center">
                                        <input type="submit"  value="Submit"  className="btn submit"/>
                                    </div>
                                    </div>
                                </form>
                    
                                </div>
                    
                            </div>
                            </div>
                                    
                            </div>

                        </div>
                    </div>
                </div>
                
                <div id="overlay">
                    <div className="cv-spinner">
                        <span className="spinner"></span>
                    </div>
                </div>
          </header>
    );
};

export default Navbar;