import React from "react";
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser'
import Select from 'react-select'
import { ThreeDots } from 'react-loader-spinner'
import checkNumber from './CheckMobileNumber';

export default function BlogDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();

    const [allblogs, setAllBlogs] = useState([])
	  const [comments, setComments] = useState([])
    const [thisBlog, setThisBlog] = useState([])
    const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const mounted = useRef();
    const [mobile, setMobileValue] = useState([]);

    const countryFile = require("./Country.js")   
  
    function selectCountry (event) {
      setStateValue([])
      const state_arr = countryFile.s_a[event.key].split("|"); 
      const stateArray = [];
      stateArray.push({
        value: '', 
        label: 'Select'
      });
      for (var i = 0; i < state_arr.length; i++) {
        stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
        });
      }
      setStates(stateArray)
      var Country = event.value;
      let check = checkNumber.checkNumber(Country,mobile);
      if(check === 2){
        setMobileValue('')
      }
    }
    
    function selectRegion (val) {
      if(val.value !== '' && val.value !== null){
        setStateValue(val)
      } else {
        setStateValue([])
      }
    }

    function updateMobile(value){
      setMobileValue(value)
    }

    useEffect(()=>{
        fetchProduct(params.id);
        const countryArray = [];
        // console.log(countryFile);
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
    },[params.id])
    const fetchProduct = async () => {
        setIsLoading(true)
        await axios.get(`/blog-page-data/${params.id}`).then(({data})=>{
          setAllBlogs(data.blogs)
          setComments(data.comments)
          setThisBlog(data.blog)
          // seBlogTitle(data.blog.title)
          setIsLoading(false)
        }).catch(({response:{data}})=>{
          setIsLoading(false)
          Swal.fire({
            text:'Error!.',
            icon:"error"
          })
        })
      }

      function notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      function commentDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ '-' +month+'-' +year;
      }

      function handleSubmit(event) {
        // console.log(event.target.country.value);
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        console.log(formData);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

      function submitComment(event){
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-comment', formData)
        .then(({data})=>{
            fetchProduct()
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }
      
  return (
    !isLoading ? 
	  thisBlog!==null?
        <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                <title>{thisBlog.title}</title>
                <meta name="description" content={thisBlog.meta_des}/>
                <meta name="keywords" content={thisBlog.meta_key}/>
                <meta property="og:image" content={`/storage/blogs/${thisBlog.image}`} />
                <meta property="og:image:secure_url" content={`/storage/blogs/${thisBlog.image}`} />
                <meta property="og:image:width" content="750" />
                <meta property="og:image:height" content="336" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content={thisBlog.title} />
                <meta name="twitter:title" content={thisBlog.title} />
                <meta name="twitter:site" content="@collegesinbangalore" />
                <meta name="twitter:image" content={`/storage/blogs/${thisBlog.image}`} />
                <meta name="twitter:image:alt" content={thisBlog.title} />
                <meta name="twitter:creator" content="@collegesinbangalore" />
            </Helmet>
              <div id="content" className="site-content container">
              <div id="content-inner" className="site-content-inner row multi-columns-row">
					  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
						 <main id="main" className="site-main">
								<div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h3 className="tm-custom-heading ">{thisBlog.title}</h3> 
                                    </div>
																		<div className="heading-seperator"><span></span>
                                    </div>
																	</header>
																	<div className="tm-cta3-content-wrapper bz-content">
																		<div className="college-img tm-featured-wrapper tm-lp_course-featured-wrapper">
                                        <img width="750" height="336" src={`/storage/blogs/${thisBlog.image}`} className="attachment-full size-full wp-post-image" alt={thisBlog.title}/>
                                    </div>	
																		<div className="blog-details">
                                    <div dangerouslySetInnerHTML={{ __html: thisBlog.content }} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
												<div className="tm-blog-classic-box-comment bz-comments">
													<div id="comments" className="comments-area">
													   <h3 className="comments-title">
														  Comments
													   </h3>
													   <ol className="comment-list">
                              {comments && comments.length > 0 && comments.map((comment, index) => {
                                return (
                                  <li className="comment even thread-even depth-1" id="comment-1" key={comment.id}>
                                    <div className="comment-body">
                                      <div className="comment-box">
                                        <div className="comment-meta commentmetadata">
                                          <cite className="tm-comment-owner fn">{comment.name}</cite>			
                                          {commentDate(new Date(comment.created_at))}
                                        </div>
                                        <div className="author-content-wrap">
                                            <p>{comment.comment}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
													   </ol>
													   <div id="respond" className="comment-respond">
														  <h5 id="reply-title" className="comment-reply-title">Leave a Reply</h5>
														  <form id="commentform" className="comment-form row" onSubmit={submitComment}>
															  <div className="col-sm-12">
                                    <label className="tm-hide" htmlFor="comment">Comment</label> 
                                    <span className="required tm-hide">*</span>
                                    <textarea id="comment" placeholder="Comment *" name="comment" cols="45" rows="8" aria-required="true" required></textarea>
                                </div>
															  <div className="col-sm-6">
                                    <label className="tm-hide" htmlFor="name">Name</label> 
                                    <span className="required tm-hide">*</span>
                                    <input id="name" placeholder="Name *" name="name" type="text" size="30" aria-required='true' required />
                                </div>
															  <div className="col-sm-6">
                                      <label className="tm-hide" htmlFor="email">Email</label> 
                                      <span className="required tm-hide">*</span>
                                      <input id="email" placeholder="Email *" name="email" type="text" size="30" aria-required='true'  required/>
                                      <input type="hidden" name="comment_id" value={thisBlog.id}/>
													           <input type="hidden" name="page" value="1"/>
                                </div>
															  <div className="col-sm-12">
                                    <input name="submit" type="submit" id="submit" className="submit tm-vc_general tm-vc_btn3 tm-vc_btn3-size-md tm-vc_btn3-shape-square tm-vc_btn3-style-flat tm-vc_btn3-color-black" value="Submit" />
                                </div>
														  </form>
													   </div>
													</div>
												 </div>
											</div>
										</div>
									</div>
								</div>
						 </main>
					  </div>
					  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#3aa0d1'}}>Recent Blogs</h3>
							<ul className="p-10 tm-recent-post-list">
              {allblogs && allblogs.length > 0 && allblogs.map((allblog, index) => {
                return (
                  <li className="tm-recent-post-list-li" key={`bl${allblog.id}`}>
                      <Link to={`/blogs/${allblog.slug}`}><img width="150" height="150" src={`/storage/blogs/${allblog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={allblog.title}/></Link>
                      <Link to={`/blogs/${allblog.slug}`}>{allblog.title}</Link>
                      <span className="post-date">{notificationDate(new Date(allblog.updated_at))}</span>
                  </li>
                )
              })}
							</ul>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor:'#a42f33'}}>Apply now</h3>
							<div className="widget-content p-10 side-form">
								<form className="course-form clearfix" onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="name" placeholder="Name *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="email" name="email" placeholder="Email *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="phone" value={mobile} onChange={e => updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" placeholder="Phone *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="course" placeholder="Course *" required/>
												<input type="hidden" name="source" value="2"/> </div>
										</div>
                    <div className="col-xs-12 pad-5">
												<div className="text-block">
                          <Select options={countries} name="country"  placeholder="Select Country" required onChange={selectCountry} />
												</div>
										</div>
                    <div className="col-xs-12 pad-5">
												<div className="text-block">
                          <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={selectRegion} />
												</div>
										</div>
										{/* <div data-toggle-group="location">
											<div className="col-xs-12 pad-5">
												<div className="text-block">
													<select name="country" id="selectCountry" className="select_2 country CountrySelect" data-toggle="country" data-country="" required> </select>
												</div>
											</div>
											<div className="col-xs-12 pad-5">
												<div className="text-block">
													<select name="state" id="selectState" className="select_2 state" data-toggle="state" data-state="" required> </select>
												</div>
											</div>
										</div> */}
                    {/* <div className="col-xs-12">
											<div className="text-block">
												<CountryDropdown
														className="country CountrySelect"
														value={country}
														name="country"
														required
                            id="mySelect"
														onChange={(val) => selectCountry(val)} />
											</div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<RegionDropdown
															className="state"
															country={country}
															value={region}
															name="state"
															required
															onChange={(val) => selectRegion(val)} />
											</div>
										</div> */}
										<div className="col-xs-12 text-center">
											<input type="submit" value="Submit" className="btn submit"/> 
                    </div>
									</div>
								</form>
							</div>
						 </aside>
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<a className="hover" href="tel:+918095797575"><h3 className="widget-title"><i className="fa fa-phone" aria-hidden="true"></i> Helpline: +918095797575</h3></a>
						 </aside>
					  </aside>
				   </div>
              </div>
        </div>
    :
    <h1> Can't connect to server ! </h1>
    :
    <div className="row">
      <div className="col-md-12 text-center">
        <ThreeDots
        visible={true}
        height="100"
        width="100"
        color="#a31c33"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{textAlign:'center'}}
        wrapperClass="loader"
        />
      </div>
    </div>
  )
};

// export default NotificationDetails;