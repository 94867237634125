import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import Helmet from 'react-helmet';
import { Audio } from 'react-loader-spinner'
import Select from 'react-select'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';



export default class Home extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          blogs:[],
          courses:[],
		  programs:[],
          countries: [], 
          states: [],
          stateValue:'',
          mobile:''
        }
      }
      
      selectCountry = (val) => {
        this.setState({ stateValue: '' });
        const state_arr = countryFile.s_a[val.key].split("|"); 
        const stateArray = [];
        stateArray.push({
          value: '', 
          label: 'Select'
        });
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        this.setState({ states: stateArray });
    
        var Country = val.value;
        let check = checkNumber.checkNumber(Country,this.state.mobile);
        if(check === 2){
          this.setState({mobile:''});
        }
      }
      
        selectRegion = (val) => {
          if(val.value !== '' && val.value !== null){
            this.setState({stateValue:val});
          } else {
            this.setState({stateValue:''});
          }
        }
        updateMobile = (value) => {
          this.setState({mobile:value});
        }
     
      async componentDidMount() {
        const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/home-page-data')
            .then(res=>{
                this.setState({
                    notifications:res.data.notifications,
                    blogs:res.data.blogs,
                    courses:res.data.courses,
					programs:res.data.programs,
                    colleges:res.data.colleges
                },()=>{
                    // console.log(this.state)
                })
            })
            .catch(err=>{
                console.log(err);
            })
      }

      resetForm(){
        document.getElementById('home-page-middle-form').reset();
		// this.setState({mobile:''});
		jQuery('.EnquiryMobile').val('');
      }

	  truncate (str) {
		return str.length > 40 ? str.substring(0, 30) + ".." : str;
	  }

      handleSubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            jQuery('#enquiry').modal('hide');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
		
      }

    render(){
        const {notifications}=this.state;
        const {blogs}=this.state;
        const {courses}=this.state;
		const {programs}=this.state;
        const {colleges}=this.state;
        const myStyle = {
          textAlign:"left",
          fontSize:"24px",
          lineHeight:"37px",
          fontFamily:"'Poppins'",
          fontWeight:"400",
          color:"#fff !important" 
        }
        const { countries, states, stateValue , mobile} = this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                    <title>Top BCom Colleges in Bangalore | Direct Admission 2024</title>
                    <meta name="description" content="Direct admission for bcom course in Christ University, MS Ramaiah, Mount Carmel College & other top ranked bcom colleges in Bangalore."/>
                    <meta name="keywords" content="Top Best bcom colleges in Bangalore, direct admissions for bcom course,best bcom colleges in Bangalore, top bcom colleges in Bangalore, bcom colleges in Bangalore"/>  
            </Helmet>
            <div id="content" className="site-content ">
             <div id="content-inner" className="site-content-inner ">
                <div id="primary" className="content-area">
                  <main id="main" className="site-main">
                      <article id="post-2133" className="post-2133 page type-page status-publish hentry">
                         <header className="single-entry-header tm-hide">
                            <h2 className="entry-title">B.Com Colleges</h2>
                         </header>
                         <div className="entry-content">
                            <div className="vc_row-full-width vc_clearfix"></div>
                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom hidden-sm hidden-md hidden-lg visible-xs">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner  ">
                                        <div className="wpb_wrapper">
                                            <div className="res-slider-form">
                                              <div className="widget">
                                                  <h4 className="widget-title text-center">Quick Enquiry</h4>
                                                  <p className="text-center">Apply now to get admission for B.Com</p>
                                                  <div className="widget-content">
                                                    <form onSubmit={this.handleSubmit}>
                                                        <div className="row">
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" name="name" placeholder="Name *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="email" name="email" placeholder="Email *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" name="phone" placeholder="Phone *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" name="course" placeholder="Course *" required/>
                                                                <input type="hidden" name="source" value="2"/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12 pad-5">
                                                            <div className="text-block">
                                                                <Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
                                                            </div>
                                                          </div>
                                                          <div className="col-xs-12 pad-5">
                                                            <div className="text-block">
                                                                <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
                                                            </div>
                                                          </div>
                                                          <div className="col-xs-12 text-center">
                                                              <input type="submit" value="Submit" className="btn submit"/> 
                                                          </div>
                                                        </div>
                                                    </form>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_bz2 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner  ">
														<div className="wpb_wrapper">
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-responsive-custom-10018428 tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-0">
																	<div className="vc_column-inner vc_custom_1567594802767 ">
																		<div className="wpb_wrapper">
																			<div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1567595065320">
																				<figure className="wpb_wrapper vc_figure">
																					<div className="vc_single_image-wrapper vc_box_border_grey"><img src="/assets/img/about.jpg" className="vc_single_image-img attachment-full lozad" alt="What is B.Com?" /></div>
																				</figure>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="wpb_column tm-column vc_column_container vc_col-sm-7 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h4 className="tm-custom-heading">What is</h4>
																						<h3 className="tm-custom-heading ">B.Com?</h3> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																				<div className="tm-cta3-content-wrapper">
																					<p>Bachelor of Commerce (B.Com) is an undergraduate course that can be completed over a duration of 3-years. B.Com is one among the most popular bachelor’s level courses that is offered by a vast number of colleges and universities. The B.Com is the most preferred choice of the majority of the students who complete their PUC or 10+2. In addition to the compulsory subjects the students of B.Com can choose their desired combinations that are offered by the institutes.</p>
																					<p>The B.Com course equips the students with an in-depth knowledge of the field of commerce pertaining to the areas in which they choose to specialise. The students are trained in the particular fields of their choice so that they gain proper knowledge and develop the ability to apply them into practical use. The B.Com course trains the students with different topics that impact businesses and trade such as accounting principles, economic policies, export and import laws etc. The main aim of the B.Com course is to prepare individuals who are equipped with knowledge and skills related to economics and business management. It helps to provide a variety of managerial skills to an aspiring student.</p>
																					<p>The B.Com course is designed to provide students with a wide range of managerial skills, while building competence in a particular area of business. It imparts candidates with knowledge in accounting principles, export and import laws, economic policies and other aspects which impact trade and business.</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

                    <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">  
															<hr/>
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className=" tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading" style={{marginTop:"20px",color:"#5ba5af"}}>Duration</h5>
																						<p>The B.Com is a full time undergraduate course with the duration of 3-years that is divided into 6-semesters.</p>
																					</div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
														</div>
													</div>
												</div>
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className=" tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading" style={{marginTop:"20px",color:"#5ba5af"}}>Eligibility</h5> </div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
																			<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">The students must have completed their 10+2 with aggregate score of 50% and above to pursue B.Com degree</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Students from any stream, who have passed PUC or 10+2 from a recognised educational board are eligible to join B.Com degree</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Students must have an age between 17 to 25 years to apply for the B.Com course.</span></li>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
                              
                    <div className="vc_row-full-width vc_clearfix"></div>
										<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom" style={{backgroundColor:"#f0f0f0"}}>
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading ">Affiliations and Approval</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
																			<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																				<p>The B.Com course is offered at both Government University and Deemed universities. Before going for details, it is always essential to understand the difference between Deemed university and Bangalore University (Government university) in Bangalore.</p>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Deemed University</b></span></li>
																				<p>A Deemed University is simply an institution that provides high standards of education in a specific area of study. The deemed university is termed as deemed to be university by the central government upon the advice of the UGC. This status helps such institutions to enjoy the same benefits and privileges that are available to any other university in the country.</p>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Bangalore University (Government)</b></span></li>
																				<p>Bangalore University (Government) is the university that is established or financed by the government of India and state governments. The government universities are established or incorporated by or under a Central Act, a Provincial Act or a State Act. They are duly recognised by UGC by default.</p>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum" id="notifications">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<hr className="hidden-sm visible-xs"/>
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading "><span className="bell fa fa-bell"></span> Notifications</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
                                 											   <ul className="m-0 tm-recent-post-list scrollable-list">
                                 											    {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                                 											      return (
                                 											      <li className="tm-recent-post-list-li" key={`n${notification.id}`}>
                                 											        <Link to={`/notifications/${notification.slug}`}>
                                 											          <img width="150" height="150" src={`/storage/notifications/${notification.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image lozad" alt={notification.title} data-loaded="true"/></Link>
                                 											            <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                 											            <span className="post-date">{this.notificationDate(new Date(notification.updated_at))}</span>
                                 											      </li>
                                 											      );
                                 											    })}
                                 											  </ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="vc_row-full-width vc_clearfix"></div>
											<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293975 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-left_center tm-bg tm-bgimage-yes">
											<div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
											<div className="vc_row container vc_row-o-equal-height vc_row-flex">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-8 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
													<div className="vc_column-inner vc_custom_1567664250316 tm-responsive-custom-75919887">
														<div className="wpb_wrapper">
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567774236927 tm-responsive-custom-64911954 tm-zindex-0">
																<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-bgcolor-skincolor vc_custom_1567774428913 tm-responsive-custom-71053255 tm-zindex-0">
																	<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-10 tm-zindex-0">
																		<div className="vc_column-inner vc_custom_1567658690491 ">
																			<div className="wpb_wrapper">
																				<h2 style={myStyle} className="tm-custom-heading vc_custom_1567661288254">Why should one choose Bangalore city to study B.Com?</h2> </div>
																		</div>
																	</div>
																</div>
																<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
																	<div className="vc_column-inner tm-responsive-custom-12751771">
																		<div className="wpb_wrapper eligibility-list">
																			<p style={{marginTop:"20px"}}>Bangalore, the capital city of Karnataka and is considered as the best educational destination for all the courses. The same holds good for B.Com studies, the students from different parts of the country come to Bangalore to pursue their B.Com degree. Bangalore is well-known as the ‘Silicon Valley” of India with numerous IT companies having their establishments here. The presence of a good number of business firms has created abundant job opportunities for the skilled professionals thereby increasing the demand in the commerce domain. Many surveys claim that on an average 30,000 to 80,000 professionals are hired in Bangalore. All these factors have made Bangalore a best destination for education.</p>
																			<p><b>Some of the reasons that make Bangalore the best destination for B.Com students are:</b></p>
																			<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Availability of Top-Notch Companies</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">All the colleges have excellent industry connect with the potential recruiters</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Presence of commerce colleges that are best in the world</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Cost Effective</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Excellent Industry Exposure</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Bangalore is the Start-up Capital of India</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Cosmopolitan LifeStyle</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Multicultural Atmosphere</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Ideal climate for study</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Quality Education and availability of excellent faculties</span></li>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
								
								<div className="vc_row-full-width vc_clearfix"></div>
										<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading">Top B.Com Programs in Bangalore</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                         						 {programs && programs.length > 0 && programs.map((programloop, index) => {
                                           							 return (
                                              							<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0" key={`p${index}`}>
                                                							<div className="vc_column-inner  ">
                                                  								<div className="wpb_wrapper">
                                                    								<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        								{programloop && programloop.length > 0 && programloop.map((program, index2) => {
                                                         								 return (
                                                         								   <li key={`pr${program.id}`}>
                                                         								     <i className="tm-skincolor fa fa-arrow-circle-right"></i> 
                                                         								     <span className="tm-list-li-content"><b>
                                                         								       <Link to={`/programs/${program.slug}`}>{program.course}</Link></b></span>
                                                         								   </li>
                                                          								)
                                                        								})}
                                                    								</ul>
                                                  								</div>
                                                							</div>
                                              							</div>
                                            								)
                                          							})}				
										 					</div>
														</div>
													</div>
												</div>
											</div>
										</div>
						<div className="vc_row-full-width vc_clearfix"></div>
                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading">Top B.Com colleges in Bangalore</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            {colleges && colleges.length > 0 && colleges.map((collegeloop, index) => {
                                              return (
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-4 tm-zindex-0" key={`clg${index}`}>
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        {collegeloop && collegeloop.length > 0 && collegeloop.map((college, index2) => {
                                                          return (
                                                            <li key={`clgi${college.id}`}>
                                                              <i className="tm-skincolor fa fa-arrow-circle-right"></i>
                                                              <span className="tm-list-li-content"><b>
                                                                <Link to={`/colleges/${college.slug}`}>{college.college}</Link></b></span>
                                                            </li>
                                                          )
                                                        })}
                                                        </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              )
                                            })}      
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>
                              <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293976 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-left_center tm-bg tm-bgimage-yes">
                                <div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
                                <div className="vc_row container vc_row-o-equal-height vc_row-flex">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-8 col-sm-offset-2 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
                                      <div className="apply-now">
                                        <div className="widget">
                                            <h4 className="widget-title text-center">Apply now</h4>
                                            <p className="text-center">Apply now to get admission for B.Com</p>
                                            <div className="widget-content">
                                              <form onSubmit={this.handleSubmit} id="home-page-middle-form">
                                                  <div className="row">
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="text" name="name" placeholder="Name *" required/> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="email" name="email" placeholder="Email *" required/> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="text" name="phone" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" placeholder="Phone *" required /> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="text" name="course" placeholder="Course *" required/>
                                                          <input type="hidden" name="source" value="2"/> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                        <Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
                                                      </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                        <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
                                                      </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 text-center hidden-xs">
                                                        <input onClick={this.resetForm} type="button" value="Reset" className="btn clear-btn"/> 
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 text-center">
                                                        <input type="submit" value="Submit" className="btn submit"/> 
                                                    </div>
                                                  </div>
                                              </form>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                            <div className="vc_row-full-width vc_clearfix"></div>
                              <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading ">Blogs</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            {blogs && blogs.length > 0 && blogs.map((blogloop, index) => {
                                              return (
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0" key={`b${index}`}>
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <ul className="m-0 tm-recent-post-list">
                                                        {blogloop && blogloop.length > 0 && blogloop.map((blog, index2) => {
                                                          return (
                                                          <li className="tm-recent-post-list-li" key={`bl${blog.id}`}>
                                                              <Link to={`/blogs/${blog.slug}`}><img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/></Link>
                                                              <Link to={`/blogs/${blog.slug}`}>{this.truncate(blog.title)}</Link>
                                                              <span className="post-date">{this.notificationDate(new Date(blog.updated_at))}</span>
                                                          </li>
                                                          )
                                                        })}
                                                        </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              )
                                            })}
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <hr className="hidden-sm visible-xs"/>
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading ">Similar Trending courses</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                    <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                    {courses && courses.length > 0 && courses.map((course, index) => {
                                                      return (
                                                        <li key={`cs${course.id}`}>
                                                          <i className="tm-skincolor fa fa-arrow-circle-right"></i>  
                                                          <span className="tm-list-li-content">
                                                            <Link to={course.url} target="_blank">{course.course}</Link>
                                                          </span>
                                                        </li>
                                                      )
                                                    })}
                                                    </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="col-sm-10 text-center">
                                                  <hr/>
                                                  <a className="apply-btn" href="#enquiry" data-toggle="modal">Apply now</a> 
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>
										<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom" style={{backgroundColor:"#f0f0f0"}}>
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading">Career Scope for B.Com</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
																			<p>Bachelor of Commerce (B.Com) is one of the most trending courses that students opt for after they complete their PUC or 10+2. The B.Com honours subjects are designed to provide individuals with a wide range of commerce and managerial skills.it is majority about having an understanding in streams like accounting, finance, taxation and management. The B.Com students can find ample job opportunities in different sectors of accounting and finance. They can even work as chartered accountants, company secretaries etc.</p>
																			<p>In addition to compulsory subjects the students pursuing B.Com can choose their combination of subjects from those offered by the institutes. The B.Com course trains the students in the particular fields  of commerce and accounts and helps them to put them into practice. The B.Com students are equipped with the knowledge about various aspects that impact business world and trade such as accounting principles. Economic policies export and import laws etc.</p>
																			<p>Although the name B.Com course sounds more academic in nature rather than professional. The B.Com graduates are quite usually placed in banking and other related sectors after graduation with an average salary package of around 4 lakhs to 10 lakhs per annum. The course trains the students on various aspects that are related to accounts such as corporate tax, economics, financial laws, company law, auditing, business management etc.</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>     
                    <div className="vc_row-full-width vc_clearfix"></div>
                      <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293977 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-bottom_center tm-bg tm-bgimage-yes">
											<div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
											<div className="vc_row container vc_row-o-equal-height vc_row-flex">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
													<div className="vc_column-inner vc_custom_1567664250317 tm-responsive-custom-75919887">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading ">B.Com Placements and Salary Packages</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-10 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper study-list">
																			<p>The B.Com students can find ample job opportunities across different sectors. They are mainly hired in banking and financial sectors. They can even find excellent job roles in accounts sections in a large number of organisations. Almost all the organisations have dedicated account sections and B.Com professionals are required here in large numbers.</p>
																			<p>The skilled B.Com students are usually hired in executive positions across different organisations at the starting of their career but they will be promoted to managerial positions as they gain experience in their careers. Further, if they take up masters in commerce then their chances of getting better roles increases. There are a number of specific domains available in banking and finance sectors. The B.Com students with specific specialisations are hired in specific domains and fields according to their specialisations. The top B.Com colleges in Bangalore have excellent industry connect and assure best of the placement opportunities in different sectors such as</p>
																			<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																				<li><i className="bz-skyblue fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Government</span></li>
																				<li><i className="bz-skyblue fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Private</span></li>
																				<li><i className="bz-skyblue fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">MNCs</span></li>
																				<li><i className="bz-skyblue fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Start-Ups</span></li>
																				<li><i className="bz-skyblue fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Small and Medium Enterprises (SMEs)</span></li>
																				<li><i className="bz-skyblue fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Multinational Companies (MNCs)</span></li>
																			</ul>
																			<p>The salary prospects and job opportunities differ from students to students. Basically, the placement opportunities depend on academic performance and skill set that the students possess with them.</p>
																			<p><b>Some of the factors that effect on the placement opportunities for the students are</b></p>
																			<div className="bz-collapse">
																			<div className="panel-group" id="bzCollapse" role="tablist" aria-multiselectable="true">
																				<div className="panel panel-default">
																				  <div className="panel-heading" role="tab" id="headingOne">
																					<h4 className="panel-title">
																					  <a role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenOne" aria-expanded="true" aria-controls="collapseOpenOne">
																						Academic Background, Performance and Record
																					  </a>
																					</h4>
																				  </div>
																				  <div id="collapseOpenOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
																					<div className="panel-body">
																					  <p>Students with an excellent academic record always ensure excellent salary packages.</p>
																					</div>
																				  </div>
																				</div>
																				<div className="panel panel-default">
																				  <div className="panel-heading" role="tab" id="headingTwo">
																					<h4 className="panel-title">
																					  <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenTwo" aria-expanded="false" aria-controls="collapseTwo">
																						Candidate Profile
																					  </a>
																					</h4>
																				  </div>
																				  <div id="collapseOpenTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
																					<div className="panel-body">
																					  <p>The smart, logical and alert candidates have the potential to earn better salaries and experience better growth in the career.</p>
																					</div>
																				  </div>
																				</div>
																				<div className="panel panel-default">
																				  <div className="panel-heading" role="tab" id="headingThree">
																					<h4 className="panel-title">
																					  <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenThree" aria-expanded="false" aria-controls="collapseThree">
																						Type of College
																					  </a>
																					</h4>
																				  </div>
																				  <div id="collapseOpenThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
																					<div className="panel-body">
																					  <p>The B.Com graduation if pursued in A+ category college or institute always ensures better salary packages because of reputation that institution holds</p>
																					</div>
																				  </div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingFour">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenFour" aria-expanded="false" aria-controls="collapseFour">
																							Additional Certifications
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
																					  <div className="panel-body">
																						<p>The Additional Certifications related to latest subjects always ensures that merit-practical exposure and skill enhancement in the students and that guarantees a high salary return.</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingFive">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenFive" aria-expanded="false" aria-controls="collapseFive">
																							Government & Private-level job selection examinations and personal interviews
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
																					  <div className="panel-body">
																						<p>The candidates having high-performance records in the interviews are always eligible for high salary packages</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingSix">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenSix" aria-expanded="false" aria-controls="collapseSix">
																							Specialisations
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
																					  <div className="panel-body">
																						<p>The colleges today offer different specialisations in B.Com courses. The specialisations play a crucial role in determining the salaries for the professionals. Hence the salaries also vary according to the specialisations in the industry.</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingSeven">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenSeven" aria-expanded="false" aria-controls="collapseSeven">
																							Type of Organisation
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenSeven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
																					  <div className="panel-body">
																						<p>The salaries also depend upon type of organisations, top MNCs and standard and reputed organisations with name and fame will offer good salary packages when compared to start-ups and small-scale industries</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingEight">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenEight" aria-expanded="false" aria-controls="collapseEight">
																							Skills
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenEight" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
																					  <div className="panel-body">
																						<p>The students with managerial skills like communication, logical, leadership, decision-making, planning, critical thinking, presentation skills and organising skills will also guarantee a very good salary.</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingNine">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenNine" aria-expanded="false" aria-controls="collapseNine">
																							Adaptability to new technology
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenNine" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
																					  <div className="panel-body">
																						<p>The students open for learning and with an ability to adapt and learn with new and advanced technology have an opportunity to get very good salary packages.</p>
																					  </div>
																					</div>
																				</div>
																			  </div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117266 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom" style={{backgroundColor:"#f0f0f0"}}>
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-10 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
																			<p>The salaries are also decent for B.Com graduates according to their positions. The salary ranges from 4 lakhs to 10 lakhs per annum for skilled B.Com graduates. The fresh B.Com students will be earning around 4 lakhs to 6 lakhs per annum at the executive levels. As they gain experience these professionals will easily earn around 6 lakhs to 10 lakhs or even more. The professionals with master degrees will expect better career growth and better salaries in a very short span of time. The salaries also depend upon the location and recruiters.</p>
																			<p>The increased demand for banking and accounting professionals and accounting gurus has resulted in availability of ample job opportunities for the B.Com students. With global economic progress there is a drastically increased number of international firms that are interested in hiring qualified Indian B.Com students.</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="vc_row-full-width vc_clearfix"></div>
										<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading">Top B.Com Recruitment Companies in Bangalore</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner">
																		<div className="wpb_wrapper">
																			<p>Bangalore is one of the hot destinations where job markets are huge. The presence of numerous companies in Bangalore has created abundant job roles for the right candidates and skilled professionals. Bangalore is referred as the “Silicon Valley of India” and there are thousands of global MNCs. Bangalore is also called as the “Start-up Capital of India” with numerous start-ups flourishing into multi-millionaire companies.</p>
																			<p><b>Some of the top organisations hiring B.Com graduates are: </b></p>
																			<div className="row text-center brand-logos">
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/tcs.jpg" alt="tcs"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/unilever.jpg" alt="unilever"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/ibm.jpg" alt="ibm"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/ey.jpg" alt="ey"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/hp.jpg" alt="hp"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/icici.jpg" alt="icici"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/microsoft.jpg" alt="microsoft"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/mckinsey.jpg" alt="mckinsey"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/deloitte.jpg" alt="deloitte"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/sony.jpg" alt="sony"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/flipkart.jpg" alt="flipkart"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/capgemini.jpg" alt="capgemini"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/aricent.jpg" alt="aricent"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/syntel.jpg" alt="syntel"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/cognizant.jpg" alt="cognizant"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/patni.jpg" alt="patni"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/snapdeal.jpg" alt="snapdeal"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/accenture.jpg" alt="accenture"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/wns.jpg" alt="wns"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/asianpaints.jpg" alt="asianpaints"/></div>	
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/nestle.jpg" alt="nestle"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/amazon.jpg" alt="amazon"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/wipro.jpg" alt="wipro"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/infosys.jpg" alt="infosys"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/intel.jpg" alt="intel"/></div>	
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/hcl.jpg" alt="hcl"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/reliance.jpg" alt="reliance"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/paytm.jpg" alt="paytm"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive" src="/assets/img/brands/phonepe.jpg" alt="phonepe"/></div>	
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
                  					<div className="vc_row-full-width vc_clearfix"></div>
                    					<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567685146842 tm-zindex-0 tm-bgcolor-skincolor tm-bgimage-position-center_center" style={{backgroundColor:"#4875ba"}}>
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0">
													<div className="vc_column-inner  ">
														<div className="wpb_wrapper">
															<div className="wpb_raw_code wpb_content_element wpb_raw_html vc_custom_1568379925491 tm-textcolor-white">
																<div className="wpb_wrapper">
																	<h5 style={{textAlign:"center",fontSize:"29px",lineHeight:"33px",fontWeight:"400"}} className="tm-custom-heading"><i className="fa fa-graduation-cap" aria-hidden="true"></i> Get Direct B.Com Admission Now, <u><Link to="#enquiry" data-toggle="modal">Click here</Link></u></h5>
                                								</div>
                              								</div>
                            							</div>
                          							</div>
                        						</div>
                      						</div> 
                  						</div>
                    <div className="vc_row-full-width vc_clearfix"></div>
                  	</div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
        );
    }
}

// export default Home;